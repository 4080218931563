<template>
  <div v-if="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="一卡通名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入一卡通名称"></a-input>
          </a-form-item>

          <a-form-item label="卡类型" name="cardType" class="ui-form__item">
            <a-select v-model:value="formState.cardType" placeholder="请选择卡类型" allow-clear style="width: 180px;">
              <a-select-option :value="1">普通卡</a-select-option>
              <a-select-option :value="2">主题卡</a-select-option>
              <a-select-option :value="3">礼品卡</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="截止时间" name="endTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.endTime"></a-range-picker>
          </a-form-item>

          <a-form-item label="生成时间" name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
			  <span v-permission="['coupon_preferential_record_import']">
				  <exportReport type="unionCardGeneratorRecordExportStrategy" :searchData="searchData"></exportReport>
			  </span>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                 :scroll="{ x: 2000 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'cardType'">
              <div v-if="record.cardType === 1">普通卡</div>
              <div v-if="record.cardType === 2">主题卡</div>
              <div v-if="record.cardType === 3">礼品卡</div>
            </template>
            <template v-if="column.key === 'endTime'">
              {{ transDateTime(record.endTime, 1) }}
            </template>
            <template v-if="column.key === 'endNum'">
              {{ record.totalNum - record.stockNum }}
            </template>
            <template v-if="column.key === 'createTime'">
              {{ transDateTime(record.createTime, 1) }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['coupon_preferential_record_detail']">
                      <a-menu-item @click="onDetail(record)">
                        详情
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_record_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_record_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_preferential_record_export']">
                      <a-menu-item>
                        导出
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
  <temp v-if="showModal" :id="id" @back="onBack(false)"></temp>
</template>

<script>
import temp from './temp.vue'
import exportReport from '@/components/exportReport/exportReport.vue';
import {
  Icon
} from '@/components/icon/icon.js';
import { getUnionCardGeneratorRecordList, unionCardGeneratorRecordUpdateStatus } from "@/service/modules/coupon";
export default {
  components: {
    Icon,
    temp,
	exportReport
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      rowSelectionType: 'checkbox',
      selectedRowKeys: [],
      searchData: {},
      formState: {},
      columns: [{
        title: '一卡通名称',
        dataIndex: 'title',
      }, {
        title: '卡类型',
        key: 'cardType',
      }, {
        title: '面值',
        dataIndex: 'parPrice',
      }, {
        title: '领取截止时间',
        key: 'endTime',
      }, {
        title: '生成数量',
        dataIndex: 'totalNum',
      }, {
        title: '已领取数量',
        key: 'endNum',
      }, {
        title: '剩余数量',
        dataIndex: 'stockNum',
      }, {
        title: '生成时间',
        key: 'createTime',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作人',
        dataIndex: 'createUserName',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  activated() {
    this.onSearch();
  },
  created() {
    this.onSearch();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    async getData() {
      if(this.formState.createTime && this.formState.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      delete this.searchData.createTime;
      if(this.formState.endTime && this.formState.endTime.length === 2) {
        this.searchData.endStartTime = this.moment(this.formState.endTime[0].startOf('day')).unix();
        this.searchData.endEndTime = this.moment(this.formState.endTime[1].endOf('day')).unix();
      }
      delete this.searchData.endTime;
      this.loading = true;
      try {
        let ret = await getUnionCardGeneratorRecordList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该生成记录吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await unionCardGeneratorRecordUpdateStatus({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDetail(item) {
      this.showModal = true;
      this.id = item.id;
    },
    onSelectChange(record, selected) {
      if (this.rowSelectionType === 'checkbox') {
        if (selected) {
          // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          // this.selectedRowData = this.selectedRowData.filter(item => {
          // 	return item.id !== record.id;
          // });
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.peopleList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
