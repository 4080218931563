import { send } from "../index";

export function getRulesList(data, opts = {}) {
  // 优惠券政策列表
  return send({
    url: "/admin/appCouponPolicy/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveRules(data, opts = {}) {
  // 优惠券政策新增
  return send({
    url: "/admin/appCouponPolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateRules(data, opts = {}) {
  // 优惠券政策编辑
  return send({
    url: "/admin/appCouponPolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteRules(data, opts = {}) {
  // 优惠券政策删除
  return send({
    url: "/admin/appCouponPolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRulesDetail(data, opts = {}) {
  // 优惠券政策详情
  return send({
    url: "/admin/appCouponPolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function disabledRules(data, opts = {}) {
  // 优惠券政策删除
  return send({
    url: "/admin/appCouponPolicy/switch.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCouponList(data, opts = {}) {
  // 优惠券列表
  return send({
    url: "/admin/appCoupon/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveCoupon(data, opts = {}) {
  // 添加优惠券
  return send({
    url: "/admin/appCoupon/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCoupon(data, opts = {}) {
  // 编辑优惠券
  return send({
    url: "/admin/appCoupon/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCouponDetail(data, opts = {}) {
  // 优惠券详情
  return send({
    url: "/admin/appCoupon/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function grantCoupon(data, opts = {}) {
  // 发放优惠券
  return send({
    url: "/admin/appCoupon/grant.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserCouponUsedList(data, opts = {}) {
  // 券使用列表
  return send({
    url: "/admin/userCoupon/userCoupon/usedList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserCouponChangeSendList(data, opts = {}) {
  // 券转赠列表
  return send({
    url: "/admin/userCoupon/userCoupon/changeSendList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppCouponArchiveCode(data, opts = {}) {
  // 优惠券生成券码
  return send({
    url: "/admin/appCoupon/getArchiveCode.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppCouponPacketList(data, opts = {}) {
  // 优惠券包列表
  return send({
    url: "/admin/appCouponPacket/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppCouponPacketDetail(data, opts = {}) {
  // 优惠券包详情
  return send({
    url: "/admin/appCouponPacket/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function addAppCouponPacket(data, opts = {}) {
  // 添加优惠券包
  return send({
    url: "/admin/appCouponPacket/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateAppCouponPacket(data, opts = {}) {
  // 编辑优惠券包
  return send({
    url: "/admin/appCouponPacket/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function grantAppCouponPacket(data, opts = {}) {
  // 发放优惠券包
  return send({
    url: "/admin/appCouponPacket/grant.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateStatusAppCouponPacket(data, opts = {}) {
  // 优惠券包启用/禁用
  return send({
    url: "/admin/appCouponPacket/updateStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardPolicyList(data, opts = {}) {
  // 一卡通政策列表
  return send({
    url: "/admin/unionCardPolicy/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateUnionCardPolicy(data, opts = {}) {
  // 编辑一卡通政策
  return send({
    url: "/admin/unionCardPolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveUnionCardPolicy(data, opts = {}) {
  // 保存一卡通政策
  return send({
    url: "/admin/unionCardPolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardPolicyDetail(data, opts = {}) {
  // 一卡通政策详情
  return send({
    url: "/admin/unionCardPolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardList(data, opts = {}) {
  // 一卡通列表
  return send({
    url: "/admin/unionCard/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardDetail(data, opts = {}) {
  // 一卡通详情
  return send({
    url: "/admin/unionCard/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveUnionCard(data, opts = {}) {
  // 添加一卡通
  return send({
    url: "/admin/unionCard/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteUnionCard(data, opts = {}) {
  // 删除一卡通
  return send({
    url: "/admin/unionCard/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateUnionCard(data, opts = {}) {
  // 编辑一卡通
  return send({
    url: "/admin/unionCard/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardArchiveCode(data, opts = {}) {
  // 一卡通生成兑换卡
  return send({
    url: "/admin/unionCard/getArchiveCode.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function grantUnionCard(data, opts = {}) {
  // 发放一卡通
  return send({
    url: "/admin/unionCard/grant.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardChargeActivityList(data, opts = {}) {
  // 一卡通充值活动
  return send({
    url: "/admin/unionCardChargeActivity/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveUnionCardChargeActivity(data, opts = {}) {
  // 添加一卡通充值活动
  return send({
    url: "/admin/unionCardChargeActivity/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteUnionCardChargeActivity(data, opts = {}) {
  // 删除一卡通充值活动
  return send({
    url: "/admin/unionCardChargeActivity/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateUnionCardChargeActivity(data, opts = {}) {
  // 编辑一卡通充值活动
  return send({
    url: "/admin/unionCardChargeActivity/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardChargeActivityDetail(data, opts = {}) {
  // 一卡通活动详情
  return send({
    url: "/admin/unionCardChargeActivity/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppCouponGeneratorRecordList(data, opts = {}) {
  // 兑换券生成记录列表
  return send({
    url: "/admin/appCouponGeneratorRecord/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function appCouponGeneratorRecordUpdateStatus(data, opts = {}) {
  // 兑换券生成记录启用/禁用
  return send({
    url: "/admin/appCouponGeneratorRecord/updateStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function appCouponGeneratorRecordAddStockNum(data, opts = {}) {
  // 兑换券生成记录添加数量
  return send({
    url: "/admin/appCouponGeneratorRecord/addStockNum.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateAppCouponGeneratorRecord(data, opts = {}) {
  // 兑换券生成记录编辑
  return send({
    url: "/admin/appCouponGeneratorRecord/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAppCouponArchiveCodeList(data, opts = {}) {
  // 兑换券列表
  return send({
    url: "/admin/appCouponArchiveCode/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function appCouponArchiveCodeUpdateStatus(data, opts = {}) {
  // 兑换券启用/禁用
  return send({
    url: "/admin/appCouponArchiveCode/updateStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardGeneratorRecordList(data, opts = {}) {
  // 兑换卡生成记录列表
  return send({
    url: "/admin/unionCardGeneratorRecord/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unionCardGeneratorRecordUpdateStatus(data, opts = {}) {
  // 兑换卡生成记录启用/禁用
  return send({
    url: "/admin/unionCardGeneratorRecord/updateStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardArchiveCodeList(data, opts = {}) {
  // 兑换卡列表
  return send({
    url: "/admin/unionCardArchiveCode/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unionCardArchiveCodeUpdateStatus(data, opts = {}) {
  // 兑换卡启用/禁用
  return send({
    url: "/admin/unionCardArchiveCode/updateStatus.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserCouponList(data, opts = {}) {
  // 优惠券信息
  return send({
    url: "/admin/userCoupon/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function disabledUserCoupon(data, opts = {}) {
  // 优惠券启用/禁用
  return send({
    url: "/admin/userCoupon/switch.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function userUnionCardChargeOrderRefund(data, opts = {}) {
  // 充值售后
  return send({
    url: "/admin/userUnionCardChargeOrder/refund.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unionCardOrderRefund(data, opts = {}) {
  // 一卡通订单售后
  return send({
    url: "/admin/unionCardOrder/refund.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function couponPacketOrderRefund(data, opts = {}) {
  // 券包订单售后
  return send({
    url: "/admin/couponPacketOrder/refund.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unionCardOrderConfirmAfterSale(data, opts = {}) {
  // 一卡通订单确认售后
  return send({
    url: "/admin/unionCardOrder/confirmAfterSale.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unionCardOrderRejectAfterSale(data, opts = {}) {
  // 一卡通订单拒绝售后
  return send({
    url: "/admin/unionCardOrder/rejectAfterSale.do",
    method: "POST",
    data,
    ...opts,
  });
}